
// require('../sass/vendor/js/all.min');

// Подключаем библиотеки
try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    window._ = require('lodash');
    window.axios = require('axios');
    window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    require('bootstrap');
    require ('./ekko-lightbox.js');
} catch (e) {}

let token = document.head.querySelector('meta[name="csrf-token"]');

// Устанавливаем токен для защиты от CSRF атак в настройки библиотеки для AJAX запросов
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found');
}

//import React from 'react';
//import ReactDOM from 'react-dom';
//// window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function () {};
//// Чат
//import SupportChat from './components/SupportChat/SupportChat';
//
//const supportChatElement = document.getElementById('supportChat');
//if (supportChatElement) {
//    ReactDOM.render(<SupportChat />, supportChatElement);
//}
//
//window.toClipBoard = function() {
//    var copyText = document.getElementById("toClipboard");
//    if (!copyText) return;
//    copyText.select();
//    document.execCommand("copy");
//};

require('./cards');

$(function () {
    //$('[data-toggle="popover"]').popover({ trigger: "hover touch" });
    $('[data-toggle="popover"]').popover({ trigger: "hover focus" });
});

$('option').each(function (i, option) {
    var oldHtml = option.innerText;
    var newHtml = oldHtml.replace(/\(([\d\.])*\)/g, function (digit) {
        var newDigits = '';
        for (var i = 0; i < digit.length; i++) {
            newDigits += digit[i] + '\u0336'
        }
        return newDigits;
    });
    option.innerText = newHtml;
});

var url = document.location.toString();
if (url.match('#')) {
    console.log("redirect to nav tab :)");
    $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
}

// Change hash for page-reload
$('.nav-tabs a').on('shown.bs.tab', function (e) {
    window.location.hash = e.target.hash;
})